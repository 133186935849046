<template>
  <div>
    <div class="main" style="height:55px;margin-bottom:13px;" v-show="routeData.path != '/login' && routeData.path != '/turntable'">
      <div class="main_left">
        <div class="main-back" v-show="routeData.path && routeData.path != '/' && routeData.path != '/category' && routeData.path.indexOf('/category/sub') < 0 && routeData.path != '/turntable' && routeData.path != '/cart' && routeData.path != '/order' &&  routeData.path != '/recommend' && routeData.path != '/review' && routeData.path != '/ordersuccess'">
          <van-icon name="arrow-left" size="24" @click="back" :class="[{prizetitle:routeData.path == '/wallet/prize'},'prizeback']"/>
        </div>
        <div class="main-title">
          <div style="display:flex;justify-content: center;align-items: flex-end;">
            <img src="../../assets/image/prizebg.png" alt="" class="prizeimg" style="vertical-align: middle;" v-if="routeData.path == '/wallet/prize'">
            <div v-if="routeData.path == '/' || routeData.path == '/category' || routeData.path.indexOf('/category/sub') > -1">
              <img src="../../assets/image/logo1.png" alt="" class="home_logo">
            </div>
            <div v-else>
              <div :class="{prizetitle:routeData.path == '/wallet/prize'}" class="title_zh">
                {{ routeData.meta.span }}
                <span class="title_en" v-if="routeData.path == '/' || routeData.path == '/category' || routeData.path.indexOf('/category/sub') > -1 || routeData.path == '/recommend' || routeData.path == '/cart' || routeData.path == '/order'"> {{ routeData.meta.spanEn }} </span>
              </div>
              <div v-if="routeData.path != '/' && routeData.path != '/category' && routeData.path.indexOf('/category/sub') < 0 && routeData.path != '/recommend' && routeData.path != '/cart' && routeData.path != '/order'" class="title_en1" :class="{prizetitle:routeData.path == '/wallet/prize'}"> {{ routeData.meta.spanEn }} </div>           
            </div>
          </div>
        </div>
      </div>
      <div class="main-right">
        <div class="main-wallet">
          <div class="wallet_main" v-show="routeData.path != '/wallet/prize'">
            <div class="wallet_num"  @click="jumpWalletDetail">
              <div>
                <img src="../../assets/image/wallet_red.png" alt="" class="home_img"  style="vertical-align:middle;margin-top:-17px">
              </div>
              <div class="numdiv">
                <div class="wallet_title" style="text-indent:3px;margin-bottom:-3px;">Wallet Balance</div>
                <span class="symoble">￡</span><countTo :startVal='0' :endVal='$store.state.wallet' :duration='1000' prefix='' separator="," :decimals="2"  class="countAmount"></countTo>
              </div>
            </div>
          </div>
          <div class="wallet_main" v-show="routeData.path == '/wallet/prize'">
            <div class="wallet_num" style="color:#000">
              <div>
                <img src="../../assets/image/wallet_red.png" alt="" class="home_img"  style="vertical-align:middle;margin-top:-17px">
              </div>
              <div class="numdiv">
                <div class="wallet_title" style="color:#000;font-weight: 400;text-indent:3px;margin-bottom:-3px;">Wallet Balance</div>
                <span class="symoble">￡</span><countTo :startVal='0' :endVal='$store.state.wallet' :duration='1000' prefix='' separator="," :decimals="2" class="countAmount"></countTo>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-search v-model="goodsName" placeholder="請輸入搜索關鍵詞" style="height:34px;margin-bottom:10px;" @search="onSearch"  v-show="this.$route.path == '/detaillist' ||  this.$route.path == '/list'"/>
    <div class="main1" style="margin-bottom:15px;height:28px;" v-show="routeData.path == '/' || routeData.path.indexOf('/category/sub') > -1 || routeData.path == '/category' || routeData.path == '/recommend' || routeData.path == '/cart' || routeData.path == '/order'">
      <div class="footer_items1">
        <div>
          <van-search v-model="goodsName" placeholder="Search" class="seach1" @click="jumpSearch" :disabled="true" style="padding:0;"/>
        </div>
        <div>
          <div class="main-items" @click="onSelect('Prizemall')">
            <van-col class="title_img"><img src="../../assets/image/wallet_mall.png" alt="" width="100%" height="100%" style="vertical-align: initial;"></van-col>
            <van-col class="footer_items_title1">獎品商城<br/>
                <span class="footer_items_title1_span">Prize mall</span>
            </van-col>
          </div>
        </div>
        <div>
          <div class="main-items" @click="onSelect('Personal')">
            <van-col class="title_img1"><img src="../../assets/image/personal1.png" alt="" width="100%" height="100%" style="vertical-align: initial;"></van-col>
            <van-col class="footer_items_title1">
                個人中心<br/>
                <span class="footer_items_title1_span">Personal</span>
            </van-col>
          </div>
        </div>
        <div>
          <div class="main-items" @click="onSelect('SignOut')">
            <van-col class="title_img2"><img src="../../assets/image/sign_out1.png" alt="" width="100%" height="100%" style="vertical-align: initial;"></van-col>
            <van-col class="footer_items_title1">
                退出登錄<br/>
                <span class="footer_items_title1_span">Sign out</span>
            </van-col>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countTo from 'vue-count-to';
export default {
  name: 'NavBar',
  components: { countTo },
  data() {
    return {
      showPopover:false,
      goodsName:"",
      routeData:{
        path:'',
        meta:{
          span:'',
          spanEn:''
        }
      },
      goodsNames:'',
      walletTimer:null
    }
  },
  watch: {
    '$route': {
      deep: true,  // 深度监听
      handler(newVal,oldVal) {
        this.routeData = newVal;
      }
    }
  },
  mounted(){
    if(this.$store.state.user.token && this.$store.state.user.token != undefined) {
      this.$store.dispatch('getWallet')
      this.init()
    }
  },
  methods: {
    init() {
        var that = this;
        clearInterval(this.walletTimer)
        this.walletTimer = null;
        this.walletTimer = setInterval(()=> {
            that.$store.dispatch('getWallet')
        },1000*60)
    },
    back() {
      this.$router.back()
      this.$store.commit('SET_LIST',{goodsName:''})
      this.goodsName = '';
    },
    More() {
      this.showPopover = true;
    },
    onSelect(action) {
      if(action == 'SignOut'){
        this.LogOut();
      }else if(action == 'Prizemall') {
        this.$router.push('/wallet/prize')
      }else if(action == 'PrizeList'){
        this.$router.push('/prize')
      }else if(action == 'home') {
        this.$router.push('/')
      }else {
        this.$router.push('/personal')
      }
      this.showPopover = false;
    },
    onSearch() {
      let parms = {
          goodsName:this.goodsName
      }
      this.$store.commit('SET_LIST',parms)
      if(this.$route.path != '/detaillist' && this.$route.path != '/list') {
        this.$router.push({
          path: '/detaillist'
        })
      }else if(this.$route.path == '/list'){
        this.$router.push({
          path: '/list',query: {id: this.$route.query.id}
        })
      }
    },
    LogOut() {
      this.$store.dispatch('LogOut').then(() => {
        sessionStorage.setItem('imgType',0)
        location.href = '/login';
      })
    },
    jumpSearch() {
      this.$store.commit('SET_LIST',{goodsName:''})
      this.$router.push({
        path: '/detaillist'
      })
    },
    jumpWalletDetail(){
        this.$router.push('/wallet/detail')
    }
  }
}
</script>

<style lang="scss" scoped>
.main{
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 0 20px;
}
.main1 {
  padding: 0px 20px;
  height: 56px;
}
.main_left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-title {
  margin-left: 10px;
  font-weight: bold;
}
.title_en {
  font-size: 0.40rem;
}
.title_zh {
  font-size: 0.6rem;
}
.main-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 auto;
}
.wallet_title {
  font-size: 0.28rem;
  font-weight: 600;
}
.wallet_main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wallet_num {
  font-size: 0.61rem;
  font-weight: bold;
  font-family: 'myRobotobold';
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.van-search {
    background: transparent;
    padding: 0px 24px;
}
.van-search__content  {
    background: #FFFFFF;
    opacity: 0.4;
    border-radius: 50px;
}

.more_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.prizetitle {
  color: #000 !important;
  font-size: 0.48rem;
}

.footer_items_title {
  font-size: 11px;
  color: #fff;
}
.footer_search {
  width: 30%;
}
.footer_items1 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
}
.title_img {
  width: 40px;
  height: 40px;
}
.title_img1 {
  width: 40px;
  height: 40px;
}
.title_img2 {
  width: 40px;
  height: 40px;
}
.footer_items_title1 {
  font-size: 0.32rem;
  color: #fff;
}
.footer_items_title1_span {
  font-size: 0.30rem;
  color: #fff;
  font-family: 'myRoboto';
}
.main-items {
  background: rgba($color: #fff, $alpha: 0.25);
  border-radius: 10px;
  text-align: center;
  display: inline-block;
  // padding: 7px 0px 7px 3px;
  padding: 7px 0px ;
  width: 162px;
  height: 56px;
  line-height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.seach1 {
  width: 200px;
  height: 56px;
  line-height: 56px;
}

/deep/ .van-search .van-cell {
  padding:0;
  height: 56px;
  line-height: 56px;
}

@font-face {
    font-family: 'myRoboto';
    src: url("../../assets/font/Roboto-Light.ttf");
}
@font-face {
    font-family: 'myRobotobold';
    src: url("../../assets/font/Roboto-Bold.ttf");
}
.symoble {
  font-size: 0.44rem;
  font-weight: bold;
}
.prize_img {
  width:44px;
  height: 43px;
}
.prizeimg {
  width:74px;
  height: 74px;
}
.home_img {
  width:55px;
  height: 68px;
}
.title_en1 {
  font-size: 0.40rem;
}
.prizeback {
  margin-top: 36px;
}
.countAmount {
  display: inline-block;
  min-width: 155px;
}
.home_logo {
  width: 300px;
  height: 89px;
}
.numdiv {
  margin-left: 0.13rem;
}
</style>
