import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import request from './utils/request'

import './utils/vant-import' // 引入移动端组件
import './utils/flexible' // 引入rem装换文件
import './assets/css/base.css' // 引入公共样式
import { parseTime , formatWeek } from "./utils/tools"
// import LuckDraw from 'vue-luck-draw'

// Vue.use(LuckDraw)

import VueLuckyCanvas from '@lucky-canvas/vue'
Vue.use(VueLuckyCanvas)

Vue.config.productionTip = false

Vue.prototype.parseTime = parseTime
Vue.prototype.formatWeek = formatWeek
// 将封装的 axios 实例添加到 Vue 原型中，以便于在每个组件中可以使用 $http 引用到封装后的 axios 实例
Vue.prototype.$http = request
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
