import request from '@/utils/request'

// 登录方法
export function login(usr, pwd) {
  const data = {
    usr,
    pwd
  }
  return request({
    url: '/app/mall/login',
    method: 'post',
    data: data
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/getInfo',
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/app/mall/logout',
    method: 'post'
  })
}

// 修改密码
export function modifyPassword(data) {
  return request({
    url: '/app/mall/changePwd',
    method: 'post',
    data:data
  })
}
