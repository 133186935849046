// 1.引入axios
import axios from 'axios'
import { getToken } from '@/utils/auth'
import Cookies from "js-cookie";
import store from '@/store'
import Vue from 'vue'

import {
  Toast,
  Notify,
  Dialog
} from 'vant'

// 2.定义访问接口的基准路径
// process.env.NODE_ENV:  development - 开发环境    production - 生产环境
// process.env包含着关于系统环境的信息
// NODE_ENV是一个用户自定义的变量，在webpack中它的用途是判断生产环境或开发环境。用与开发模拟接口的备用地址
// const baseURL = process.env.NODE_ENV === 'development' ? 'http://rap2api.taobao.org/app/mock/289076' : 'http://www.xiongmaoyouxuan.com'
const baseURL = '/prod-api'
// 3.配置axios实例默认值
// 在创建实例时设置配置默认值
const instance = axios.create({
  baseURL: baseURL
})
const errorlist = {
  '401': '认证失败，无法访问系统资源',
  '403': '当前操作没有权限',
  '404': '访问资源不存在',
  'default': '系统未知错误，请反馈给管理员'
}
// 4.添加请求拦截器
instance.interceptors.request.use(config => {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  if (getToken() && !isToken) {
    config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?';
    for (const propName of Object.keys(config.params)) {
      const value = config.params[propName];
      var part = encodeURIComponent(propName) + "=";
      if (value !== null && typeof(value) !== "undefined") {
        if (typeof value === 'object') {
          for (const key of Object.keys(value)) {
            let params = propName + '[' + key + ']';
            var subPart = encodeURIComponent(params) + "=";
            url += subPart + encodeURIComponent(value[key]) + "&";
          }
        } else {
          url += part + encodeURIComponent(value) + "&";
        }
      }
    }
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  return config
}, error => {
    console.log(error)
    Promise.reject(error)
})

// 5.添加响应拦截器
instance.interceptors.response.use(res => {
   // 未设置状态码则默认成功状态
   const code = res.data.code || 200;
   // 获取错误信息
   const msg = errorlist[code] || res.data.msg || errorlist['default']
  if (code === 401) {
      Dialog.confirm({
        title: '系统提示',
        message: '登录状态已过期，您可以继续留在该页面，或者重新登录'
      }
      ).then(() => {
        store.dispatch('LogOut').then(() => {
          location.href = '/login';
        })
      })

  } else if (code === 500) {
    Notify({ type: 'danger', message: msg });
    return Promise.reject(new Error(msg))
  } else if (code !== 200) {
    if(code != 10008 && code != 10000) {
      Notify({ type: 'danger', message: msg });
      return Promise.reject('error').catch(e => {
        return res.data;
      })
    }else {
      return Promise.reject('error').catch(e => {
        return res.data;
      })
    }  
  } else {
    return res.data
  }
},
error => {
  console.log('err' + error)
  let { message } = error;
  if (message == "Network Error") {
    message = "后端接口连接异常";
  }
  else if (message.includes("timeout")) {
    message = "系统接口请求超时";
  }
  else if (message.includes("Request failed with status code")) {
    message = "系统接口" + message.substr(message.length - 3) + "异常";
  }
  Notify({ type: 'danger', message: msg ,duration: 1000*5});
  return Promise.reject(error)
})

export default instance
