const CarPlugin = store => {
  // 注册监听，在每次 mutation 被调用之后都会执行回调函数
  store.subscribe((mutation, state) => {
    // console.log('mutation调用 ...', mutation, state)
    // 将购物车 cart 数组中的数据缓存到本地存储中
    localStorage.setItem('cart', JSON.stringify(state.cart))
  })
}

export default CarPlugin
