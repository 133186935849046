<template>
  <div id="app" >
    <header :class="{prizebg:$route.path == '/wallet/prize'}">
      <!-- <router-view name="header" /> -->
      <NavBar v-if="$route.path != '/login'"></NavBar>
    </header>
    <main :class="{prizemain:$route.path == '/wallet/prize'}">
        <router-view />
        <!-- <floatBtn v-if="$route.meta.isWallet" /> -->
    </main>
    <footer style="z-index:100000" id="footer">
      <!-- <router-view name="footer" /> -->
      <TabBar v-if="$route.meta.ButtonRouterLink"></TabBar>
    </footer>
  </div>
</template>

<script>
import NavBar from './components/nav-bar'
import TabBar from './components/tab-bar'
import floatBtn from './views/wallet/popups.vue';
export default {
  name: 'App',
  components:{
    NavBar,
    TabBar,
    floatBtn
  },
  created() {
    if (sessionStorage.getItem("userLevel") ) {
      this.$store.state.user.userLevel = JSON.parse(sessionStorage.getItem("userLevel"))
    }
    window.addEventListener("beforeunload",()=>{
      sessionStorage.setItem("userLevel",JSON.stringify(this.$store.state.user.userLevel))
    })
  }
}
</script>

<style lang="scss">
html,
body,
#app {
  height: 100%;
  font-size: 25px;
  background: url('./assets/image/bg_gwc.png') no-repeat;
  background-size: 100% 100%;
}
#app {
  display: flex;
  flex-direction: column;

  main {
    flex: 1;
    overflow-x: hidden;
  }
  
}
.prizebg {
  background: linear-gradient(#FEDE4C,#FEC751); 
}
.prizemain {
  background: #FFFBF0;
}
</style>
