import { login, logout, getInfo } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'

const user = {
  state: {
    token: getToken(),
    name: '',
    avatar: '',
    roles: [],
    permissions: [],
    userLevel:'0'
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_USER_LEVEL: (state, level) => {
      state.userLevel = level
    },
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const usr = userInfo.tel
      const pwd = userInfo.password
      return new Promise((resolve, reject) => {
        login(usr, pwd).then(res => {
          let userData = {
            phonenumber: res.data.phonenumber,
            nickName:res.data.nickName
          }
          localStorage.setItem('cart',[])
          localStorage.setItem('orderObject',null)
          sessionStorage.setItem('userData',null)
          sessionStorage.setItem('imgType',0)
          sessionStorage.setItem('path', 'Hot')
          sessionStorage.setItem('userData',JSON.stringify(userData))
          setToken(res.data.token)
          commit('SET_USER_LEVEL',res.data.userLevel)
          commit('SET_TOKEN', res.data.token)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo().then(res => {
          const user = res.user
          // const avatar = user.avatar == "" ? require("@/assets/image/profile.jpg") : '/prod-api' + user.avatar;
          // if (res.roles && res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
          //   commit('SET_ROLES', res.roles)
          //   commit('SET_PERMISSIONS', res.permissions)
          // } else {
          //   commit('SET_ROLES', ['ROLE_DEFAULT'])
          // }
          commit('SET_NAME', user.tel)
          // commit('SET_AVATAR', avatar)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    
    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token).then(() => {
          localStorage.setItem('cart',[])
          localStorage.setItem('orderObject',null)
          sessionStorage.setItem('userData',null)
          sessionStorage.setItem('imgType',0)
          sessionStorage.setItem('path', 'Hot')
          sessionStorage.setItem('userLevel', '0')
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          commit('SET_PERMISSIONS', [])
          commit('SET_USER_LEVEL','0')
          removeToken()
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    }
  }
}

export default user
