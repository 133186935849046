// 路由懒加载
const Cart = () => import('../views/cart')
const Detail = () => import('../views/detail')
const Detaillist = () => import('../views/detail/listPage')
const Login = () => import('../views/login')
const Category = () => import('../views/category')
const Order = () => import('../views/order')
const OrderDeatil = () => import('../views/order/detailPage')
const Turntable = () => import('../views/turntableactivity')
// 引入嵌套的组件
const CategoryBar = () => import('../views/category/CategoryBar')
const Cartresult = () => import('../views/cart/result')
const prizeList = () => import('../views/prize')
const prouctList = () => import('../views/category/listPage')
const Recommend = () => import('../views/recommend')
const Personal = () => import('../views/personal/index')
const Password = () => import('../views/personal/password')
const ReviewOrder = () => import('../views/evaluation')
const OrderSuccess = () => import('../views/order/success')
const Refund = () => import('../views/refund')
const Wallet = () => import('../views/wallet/detail')
const Prizemall = () => import('../views/wallet/prize')
// 静态路由路径配置信息
const routes = [{
  path: '/',
  redirect: '/category'
}, {
  path: '/detail',
  name: 'Detail',
  component: Detail,
  meta: {
    span: '詳情',
    spanEn:'Detail',
    isWallet:true
  }
}, {
  path: '/detaillist',
  name: 'Detaillist',
  component: Detaillist,
  meta: {
    span: '搜索',
    spanEn:'Search',
    isWallet:true,
    ButtonRouterLink:true,
  }
},{
  path: '/login',
  name: 'Login',
  component: Login,
  meta: {
    span: '登錄',
    isWallet:false
  },
}, {
  path: '/category',
  name: 'Category',
  component: Category,
  meta: {
    icon: 'home-o',
    span: '商品',
    spanEn:'Shop',
    to: '/category',
    inTabbar: true,
    ButtonRouterLink:true,
    isWallet:false
  },
  children: [{
    // 以 ： 开头的参数称为动态路径参数,该动态路劲参数可以使用 $route.params 获取
    path: 'sub/:id', // 完整路径： /category/sub
    name: '',
    component: CategoryBar,
    meta: {
      span: 'Pacific Foods',
      ButtonRouterLink:true,
      isWallet:false
    }
  }]
}, 
{
  path: '/list', 
  name: 'list',
  component: prouctList,
  meta: {
    span: '商品列表',
    spanEn:'Product List',
    isWallet:true,
    ButtonRouterLink:true,
  }
},
{
  path: '/recommend',
  name: 'Recommend',
  component: Recommend,
  meta: {
    icon: 'hot-o',
    span: '最愛',
    spanEn:'Favourites',
    to: '/recommend',
    inTabbar: true,
    ButtonRouterLink:true,
    isWallet:true
  }
}, 
{
  path: '/cart',
  name: 'Cart',
  component: Cart,
  meta: {
    icon: 'shopping-cart-o',
    span: '購物車',
    spanEn:'Cart',
    to: '/cart',
    inTabbar: true,
    ButtonRouterLink:true,
    isWallet:true
  }
}, 
{
  path: '/order',
  name: 'Order',
  component:Order,
  meta: {
    icon: 'orders-o',
    span: '訂單',
    spanEn:'Orders',
    to: '/order',
    inTabbar: true,
    ButtonRouterLink:true,
    isWallet:true
  }
},{
  path: '/orderdetail',
  name: 'Orderdetail',
  component:OrderDeatil,
  meta: {
    span: '訂單詳情',
    spanEn:'Orders Details',
    isWallet:true
  }
},{
  path: '/turntable',
  name: 'Turntable',
  component:Turntable,
  meta: {
    span: '訂單結果',
    spanEn:'Order Result',
  }
},
{
  path: '/cart/result', 
  name: 'result',
  component: Cartresult,
  meta: {
    span: '訂單支付',
    spanEn:'Order payment',
    isWallet:true
  }
},
{
  path: '/prize', 
  name: 'prize',
  component: prizeList,
  meta: {
    span: '獎品列表',
    spanEn:'Prize List',
  }
},
{
  path: '/personal', 
  name: 'personal',
  component: Personal,
  meta: {
    span: '個人中心',
    spanEn:'Personal',
  }
},
{
  path: '/password', 
  name: 'password',
  component: Password,
  meta: {
    span: '修改密碼',
    spanEn:'change Password',
  }
},
{
  path: '/review', 
  name: 'review',
  component: ReviewOrder,
  meta: {
    span: '訂單評價',
    spanEn:'Review Order',
  }
},
{
  path: '/ordersuccess', 
  name: 'ordersuccess',
  component: OrderSuccess,
  meta: {
    span: '訂單',
    spanEn:'Orders',
  }
},
{
  path: '/refund', 
  name: 'refund',
  component: Refund,
  meta: {
    span: '退貨',
    spanEn:'refund',
    isWallet:true
  }
},
{
  path: '/wallet/detail', 
  name: 'detail',
  component: Wallet,
  meta: {
    span: '錢包餘額',
    spanEn:'Wallet Balance',
    isWallet:false
  }
},
{
  path: '/wallet/prize', 
  name: 'detail',
  component: Prizemall,
  meta: {
    span: '獎品商城',
    spanEn:'Prize Mall',
    isWallet:false
  }
}
]

// 导出路由数组
export default routes
