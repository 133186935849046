<template>
  <ul class="tab-bar">
    <li
      v-for="item in lis"
      :key="item.meta.id"
      is="router-link"
      :to="item.path"
      tag="li"
    >
      <!-- <i class="iconfont" v-html="item.meta.icon"></i> -->
      <van-icon :name="item.meta.icon" />
      <span>{{ item.meta.span }}</span>
      <span style="font-size:12px">{{ item.meta.spanEn }}</span>
    </li>
  </ul>
</template>

<script>
import routes from '@/router/routes'
export default {
  name: 'TabBar',
  // 计算在 tab-bar 上显示的 tab 项相关数据
  computed: {
    lis() {
      return routes.filter(item => item.meta?.inTabbar)
    }
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "iconfont"; /* Project id 2751110 */
  src: url("//at.alicdn.com/t/font_2751110_fzzi2fx9u9e.woff2?t=1629203113722")
      format("woff2"),
    url("//at.alicdn.com/t/font_2751110_fzzi2fx9u9e.woff?t=1629203113722")
      format("woff"),
    url("//at.alicdn.com/t/font_2751110_fzzi2fx9u9e.ttf?t=1629203113722")
      format("truetype");
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
.tab-bar {
  display: flex;
  max-height: 150px;
  width: 100%;
  background-color: #fff;

  li {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    i {
      margin: 0 0 3px 0;
      font-size: 22px;
    }

    span {
      font-size: 18px;
    }
  }
}
.router-link-exact-active,
.router-link-active {
  color: #67B346;
}
.image{
  width: 50px;
  height: 50px;
}
</style>
