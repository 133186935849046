<template>
    <div 
    style="position: fixed;
    z-index: 6000000;
    left: 0;
    top: 60px"
    @touchstart="touchstart($event)"
    @touchmove="touchMove($event)"
    @touchend="touchEnd($event)"
   >
   <!--   -->
        <div  id="main"  style="position: absolute;left: -100px;">
            <div class="wallet" style="font-size: 14px; color: rgba(143, 205, 90, 1);" >
                <div style="display: inline-block;white-space:nowrap;text-align: center;border: 2px solid #8FCD5A;
                    border-right:none;border-radius: 60px;padding: 2px 40px 2px 5px;background: #fff;" v-if="isShow" @click="jumpWalletDetail">
                    <countTo :startVal='0' :endVal='$store.state.wallet' :duration='1000' prefix='￡' separator="," :decimals="2"></countTo>
                </div>  
                <div style="padding: 2px 40px 2px 5px;width:114px;" v-if="!isShow"></div>
            </div>
            <img src="../../assets/image/wallet.gif" alt="" v-show="imgType"  id="walletimg" style="width:45px;vertical-align:middle;position:absolute;right:-18px;top:-76px" @click="showWallet">
            <img src="../../assets/image/wallet1.png" alt="" v-show="!imgType" id="walletimg" style="width:40px;height: 45px;vertical-align:middle;position:absolute;right:-13px;top:-14px" @click="showWallet">
        </div>
    </div>
</template>
<script>
import countTo from 'vue-count-to';
export default {
    components: { countTo },
    data() {
        return {
            timeOutEvent: 0,
            longClick: 0,
            // 手指原始位置
            oldMousePos: {},
            // 元素原始位置
            oldNodePos: {},
            isShow:false,
            timer:null,
            num:0,
            walletTimer:null,
            oldAmount:0,
            imgType:false
        }
    },
    watch:{
        '$store.state.wallet':{
            handler(newValue,oldValue) {
                this.oldAmount = oldValue;
                if(this.oldAmount > newValue) {
                    this.imgType = true;
                }
                this.oldAmount = newValue;
            },
            deep:true
        }
    },
    created() {
        this.$store.dispatch('getWallet')
        this.imgType = sessionStorage.getItem('imgType') == 0 ? false : true;
    },
    mounted() {
        // var  that = this;
        // setTimeout(()=> {
        //     that.init()
        // },1000)
    },
    destroyed() {
        clearInterval(this.walletTimer)
        this.walletTimer = null;
    },
    methods:{
        init() {
            var that = this;
            clearInterval(this.walletTimer)
            this.walletTimer = null;
            this.walletTimer = setInterval(()=> {
                that.$store.dispatch('getWallet')
            },1000*60)
        },
        touchstart(ev) {
            // 定时器控制长按时间，超过500毫秒开始进行拖拽
            // this.timeOutEvent = setTimeout(() => {
                this.longClick = 1;
            // }, 100);
            const selectDom = ev.currentTarget;
            const { pageX, pageY } = ev.touches[0]; // 手指位置
            const { offsetLeft, offsetTop } = selectDom; // 元素位置
            // 手指原始位置
            this.oldMousePos = {
                x: pageX,
                y: pageY
            };
            // 元素原始位置
            this.oldNodePos = {
                x: offsetLeft,
                y: offsetTop
            };
            selectDom.style.left = `${offsetLeft}px`;
            selectDom.style.top = `${offsetTop}px`;
        },
        touchMove(ev) {
            // 未达到500毫秒就移动则不触发长按，清空定时器
            clearTimeout(this.timeOutEvent);
            if (this.longClick === 1) {
                const selectDom = ev.currentTarget;
                // x轴偏移量
                const lefts = this.oldMousePos.x - this.oldNodePos.x;
                // y轴偏移量
                const tops = this.oldMousePos.y - this.oldNodePos.y;
                const { pageX, pageY } = ev.touches[0]; // 手指位置
                selectDom.style.left = `${pageX - lefts}px`;
                selectDom.style.top = `${pageY - tops}px`;
            }
        },
        touchEnd(ev) {
            // 清空定时器
            clearTimeout(this.timeOutEvent);
            if (this.longClick === 1) {
                this.longClick = 0;
                const selectDom = ev.currentTarget;
                const {clientWidth, clientHeight} = document.body;
                const {offsetLeft, offsetTop} = selectDom;
                // if(offsetLeft+60 > (clientWidth / 2)) {
                //     selectDom.style.left =  'calc(100% - 145px)'
                // }else {
                //     selectDom.style.left =  0
                // }
                selectDom.style.left =  0
                // if(offsetLeft < 10) {
                //     selectDom.style.left = 0;
                // }else if(offsetLeft + 10 > clientWidth){
                //     selectDom.style.left = 'calc(100% - 7%)';
                // }else {
                //     selectDom.style.left = offsetLeft;
                // }
                if (offsetTop < 10) {
                    selectDom.style.top = '10px';
                } else if (offsetTop + 35 > clientHeight) {
                    selectDom.style.top = `${clientHeight - 35}px`;
                }
            }
        },
        showWallet() {
            if(this.num % 2 == 0) {
				this.open();
				this.num++;
			} else {
				this.close();
				this.num++;
			}
        },
        open() {
            var that = this;
            sessionStorage.setItem('imgType',0)
            this.imgType = false;
            var oDiv = document.getElementById('main');
            let offsetLeftnum = JSON.parse(JSON.stringify(oDiv.offsetLeft));
            clearInterval(that.timer);
            that.timer = setInterval(function() {
                if(offsetLeftnum == 0) {
                    clearInterval(that.timer);
                } else {
                    offsetLeftnum = parseFloat((offsetLeftnum+10).toFixed(2));
                    oDiv.style.left = offsetLeftnum + 'px';
                    that.isShow = true;
                }
            }, 30);
        },
        close() {
            var that = this;
            sessionStorage.setItem('imgType',0)
            this.imgType = false;
            var oDiv = document.getElementById('main');
            let offsetLeftnum = JSON.parse(JSON.stringify(oDiv.offsetLeft));
            clearInterval(that.timer);
            that.timer = setInterval(function() {
                if(oDiv.offsetLeft == -100) {
                    clearInterval(that.timer);
                    that.isShow = false;
                } else {
                    offsetLeftnum = parseFloat((offsetLeftnum-10).toFixed(2));
                    oDiv.style.left = offsetLeftnum + 'px';
                }
            }, 30);
        },
        jumpWalletDetail(){
            this.$router.push('/wallet/detail')
        }
    }
}
</script>
<style scoped>

</style>