const seach = {
    state: {
        seachObj: {
            goodsName:''
        }
    },
    mutations: {
        SET_LIST (state, payload) {
            state.seachObj.goodsName = payload.goodsName;
        }
    },
    actions: {

    }
}
export default seach