import Vue from 'vue'
import VueRouter from 'vue-router'
// 导入路由数组
import routes from './routes'
// 引入 vuex 的 store
import store from '../store'

// 使用路由插件
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
// 创建 VueRouter 对象实例
const router = new VueRouter({
  mdoe: 'hash', // 路由模式
  routes // 路由数组
})

// 全局前置守卫
const permissionRoutes = ['/order','/cart','/category'] //'/order'
router.beforeEach((to, from, next) => {
  // console.log(to, from)
  if (permissionRoutes.includes(to.path)) {
    // 有 token 数据，则说明用户已登录成功，
    // 如果没有 token 数据，则跳转到登录页面
    if (store.state.user.token) {
      next()
    } else {
      next('/login')
    }
  } else {
    next()
  }
})

export default router
