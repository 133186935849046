import Vue from 'vue'
import Vuex from 'vuex'
import cartPlugin from './plugin/plugin'
import userModule from './modules/users'
import seachModule from './modules/seach'
import {deletedCart,changeCartnum} from  '@/api/cart'
import {getWallet} from "../api/personal"
import {Notify} from "vant";
// 使用 Vuex 
Vue.use(Vuex)

// 创建 Vuex.Store 对象实例
const store = new Vuex.Store({
  // strict: process.env.NODE_ENV !== 'production', // 严格模式，不要在生产环境中开启严格模式!!!
  plugins: [cartPlugin],
  state: { // 状态初始化
    cart: [],
    wallet:0
  },
  mutations: { // 保存所有同步更新状态时的 mutation
    /**
     * 向购物车中添加商品
     * @param {*} state 状态
     * @param {*} payload 有效载荷，待添加到购物车数组中的当前选购商品对象，{id, title, image, num, price}
     */
    addToCart(state,payload) {
      let parmas = {
          "goodsId": payload.id,
          "propertyType": payload.type,
          "valueType": payload.valueType
      }
      changeCartnum(parmas).then(res => {
        if(res.code == 200) {
          payload.inNum = res.data.inNum;
          payload.outNum = res.data.outNum;
        }else {
          Notify({ type: 'danger', message: res.msg });
        }
      })
    },
    /**
     * 删除购物车中商品数量
     * @param state
     * @param payload 传递待修改数量的商品id与修改后的数量值 {id, num}
     */
    removeFromCart(state, payload) {
      deletedCart({goodsId:payload}).then(res => {})
    },
    getCarts(state,payload) {
      state.cart = payload;
    },
    setWallet(state,data) {
      state.wallet = data
    }
  },
  actions: {
    //请求购物车数据
    // reqCartShoping({ commit }) {
    //   cartList().then(res => {
    //     commit('setCart', { cart: res });
    //   })
    // },
    getWallet({commit}) {
      getWallet().then(res => {
        let num = res.data.amount;
        commit('setWallet', num);
      })
    }
  },
  modules: {
    user: userModule,
    seach: seachModule
  },
  getters: {
    // 计算购物车商品的合计金额
    total(state) {
      let result = 0
      state.cart.forEach(item => {
        if(item.tax) {
          result += Math.floor(item.num * item.unitPrice*1 * 100)/100 + Math.floor(item.num * item.unitPrice*1 * 0.2 *100)/100*1
        }else {
          result += Math.floor(item.num * item.unitPrice*1 * 100)/100*1
        }
      });
      return result*100;
    },
    //return state.cart.reduce((result, item) => (result += item.num * item.price), 0) * 100
    // return state.cart.reduce((result, item) => {
    //   console.log(item);
    //   if(item.isvat) {
    //     result += (item.num * item.price*1 ) + Math.floor(item.num * item.price*1 * item.taxi)
    //   }else {
    //     result += item.num * item.price*1
    //   }
    // }, 0) * 100
    // getWalletNum(state) {
    //   return state.wallet;
    // }
  }
})

export default store
