import Vue from 'vue'
import {
  Swipe,
  SwipeItem,
  Lazyload,
  Tab,
  Tabs,
  Card,
  Tag,
  List,
  Sidebar,
  SidebarItem,
  Grid,
  GridItem,
  GoodsAction,
  GoodsActionIcon,
  GoodsActionButton,
  Icon,
  SwipeCell,
  Button,
  Empty,
  Stepper,
  SubmitBar,
  Form,
  Field,
  Popup,
  Sku,
  Checkbox,
  Search,
  Cell,
  Col, 
  Row,
  Divider,
  Dialog,
  Notify,
  Popover,
  Cascader,
  DropdownMenu, 
  DropdownItem,
  Toast,
  NavBar,
  ActionSheet,
  Rate,
  RadioGroup,
  Radio,
  Overlay,
  Loading 
} from 'vant'

import { Image as VanImage } from 'vant';

Vue.use(Swipe) // 全局的组件注册，组件名称统一加了 `van-` 的前缀
Vue.use(SwipeItem)
Vue.use(Lazyload)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Card)
Vue.use(Tag)
Vue.use(List)
Vue.use(Sidebar)
Vue.use(SidebarItem)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(GoodsAction)
Vue.use(GoodsActionButton)
Vue.use(GoodsActionIcon)
Vue.use(Icon)
Vue.use(SwipeCell)
Vue.use(Button)
Vue.use(Empty)
Vue.use(Stepper)
Vue.use(SubmitBar)
Vue.use(Form)
Vue.use(Field)
Vue.use(Popup)
Vue.use(Sku)
Vue.use(Checkbox)
Vue.use(Search)
Vue.use(Cell)
Vue.use(Col);
Vue.use(Row);
Vue.use(Divider)
Vue.use(Dialog)
Vue.use(Notify)
Vue.use(Popover)
Vue.use(Cascader)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(GridItem)
Vue.use(VanImage)
Vue.use(Toast)
Vue.use(NavBar)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(ActionSheet)
Vue.use(Rate)
Vue.use(Overlay)
Vue.use(Loading)
