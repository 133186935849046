 /**
  * 根据选择器查找满足条件的第一个元素
  */
 function $(selector) {
   return document.querySelector(selector)
 }

 /**
  * 获取元素的 css 属性值
  */
 function css(element, name) {
   return getComputedStyle(element, null)[name]
 }
 /**
  * 多属性运动函数
  * @param element 待运动的元素
  * @param options 多属性选项对象，如：{top: 100, left: 200, width: 300, opacity: 1}
  * @param duration 限定运动时长，可选，未传递，默认为 400ms
  * @param fn 函数，运动结束后继续执行的函数
  */
 function animate(element, options, duration = 400, fn) {
   // 为 duration 赋默认值
   // duration = duration || 400
   clearInterval(element.timer)
   const start = {}
   const speed = {}
   for (const key in options) {
     start[key] = parseFloat(css(element, key))
     speed[key] = (options[key] - start[key]) / duration
   }
   const startTime = Date.now()
   element.timer = setInterval(function () {
     const elapsed = Math.min(duration, Date.now() - startTime)
     for (const key in options) {
       const distance = elapsed * speed[key]
       element.style[key] = start[key] + distance + (key === 'opacity' ? '' : 'px')
     }
     if (elapsed === duration) {
       clearInterval(element.timer)
       fn && fn()
     }
   }, 1000 / 60)
 }
 /**
  * 淡入
  * @param element 待运动的元素
  * @param duration 限定运动时长
  * @param fn 函数，运动结束后继续执行的函数
  */
 function fadeIn(element, duration = 400, fn) {
   element.style.display = 'block'
   element.style.opacity = 0
   animate(element, {
     opacity: 1
   }, duration, fn)
 }

 /**
  * 淡出
  * @param element 待运动的元素
  * @param duration 限定运动时长
  * @param fn 函数，运动结束后继续执行的函数
  */
 function fadeOut(element, duration = 400, fn) {
   animate(element, {
     opacity: 0
   }, duration, function () {
     element.style.display = 'none'
     fn && fn()
   })
 }
 /**
  * 缓慢运动函数
  * @param obj 待运动的元素
  * @param target 移动目标距离
  * @param callback 函数，运动结束后继续执行的函数
  */
 function animation(obj, target, callback) {
   // 先清除以前的定时器，只保留当前的一个定时器执行
   clearInterval(obj.timer);
   obj.timer = setInterval(function () {
     // 把我们步长值改为整数 不要出现小数的问题
     var step = (target - obj.offsetLeft) / 10;
     step = step > 0 ? Math.ceil(step) : Math.floor(step);
     if (obj.offsetLeft == target) {
       clearInterval(obj.timer);
       // 回调函数写到定时器结束里面
       // if (callback) {
       //     // 调用函数
       //     callback();
       // }
       callback && callback(); // 逻辑中断 等于if
     }
     obj.style.left = obj.offsetLeft + step + 'px';

   }, 15);
 }

 /**
  * 得到一个两数之间的随机整数
  * @param min 最小的值
  * @param max 最大的值
  */
 function getRandom(min, max) {
   min = Math.ceil(min);
   max = Math.floor(max);
   return Math.floor(Math.random() * (max - min + 1)) + min; //含最大值，含最小值 
   // return Math.floor(Math.random() * (max - min)) + min;    不含最大值，含最小值
 }

 /**
  * 返回格式时间  如： 2021年07月09日星期五 21:03:40  
  */
 function getTime() {
   var today = new Date();
   var year = today.getFullYear();
   var month = today.getMonth() + 1;
   month = month < 10 ? '0' + month : month;
   var date = today.getDate();
   date = date < 10 ? '0' + date : date;
   var arr = ['星期天', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
   var day = today.getDay();
   var week = arr[day];
   var h = today.getHours();
   h = h < 10 ? '0' + h : h;
   var m = today.getMinutes();
   m = m < 10 ? '0' + m : m;
   var s = today.getSeconds();
   s = s < 10 ? '0' + s : s;
   return year + '年' + month + '月' + date + '日' + week + ' ' + h + ':' + m + ':' + s;
 }

 /**
  * 返回距离截止还剩多少时间  
  * @param inputTime 输入到达的时间点   var inputTime = +new Date('2021-07-05 12:00:00');
  */
 function countDown(inputTime) {
   var nowTime = +new Date();
   var timeDif = (inputTime - nowTime) / 1000;
   var h = parseInt(timeDif / 60 / 60 % 24);
   h = h < 10 ? '0' + h : h;
   var m = parseInt(timeDif / 60 % 60);
   m = m < 10 ? '0' + m : m;
   var s = parseInt(timeDif % 60);
   s = s < 10 ? '0' + s : s;
   return h + ':' + m + ':' + s;
 }

 /**
  * ajax
  * @param options ajax 选项对象，对象结构如：{url, method, data, dataType, success, error}
  *  url: 请求资源的路径
  *  method：请求方法，默认为 GET
  *  data：对象，向服务端发送的数据，如：{username: 'admin', password: 'admin'}
  *  dataType：预期从服务端返回的数据格式，默认为 json
  *  success：函数，是请求成功后执行的回调函数，传递请求成功的数据作为参数，如：function(data) {}
  *  error：函数，是请求失败后执行的回调函数
  */
 function ajax(options = {}) {
   // 解构在 options 选项中的属性
   let {
     url,
     method = 'GET',
     data,
     dataType = 'json',
     success,
     error
   } = options

   if (!url) {
     throw new Error('ajax 请求必须提供 url 地址')
   }

   // 处理用于向后端发送的数据
   let params = null
   if (data) {
     // 临时在 params 中保存数组数据
     params = []
     // 迭代 data 对象中各属性 例：{username: 'admin', password: 'admin'}
     for (const key in data) {
       params.push(`${key}=${data[key]}`) // ['username=admin', 'password=admin']
     }
     // 将数组中每个元素以 `&` 符号拼接，生成查询字符串结构
     params = params.join('&')
   }

   // 如果是 GET 数据，并存在向后端发送的数据，则将查询字符串以 ? 号拼接在 URL 后
   if (method === 'GET' && params) {
     url += `?${params}`
     params = null
   }

   // 创建核心对象
   const xhr = new XMLHttpRequest()
   // 打开，建立连接
   xhr.open(method, url, true)
   // 设置数据头
   xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
   // 发送请求
   xhr.send(params)
   // 处理响应
   xhr.onreadystatechange = function () {
     // 请求处理完毕，响应就绪
     if (xhr.readyState === 4) {
       // HTTP 状态码 2xx 表示成功
       if (xhr.status >= 200 && xhr.status < 300) {
         // 获取响应数据
         let data = xhr.responseText
         // 如果预期从后端返回的是 JSON 格式的数据，则需要进行 JSON 解析转换
         if (dataType === 'json') {
           data = JSON.parse(data)
         }
         // 后续数据处理逻辑
         success && success(data)
       } else {
         // 有错误，则传递HTTP状态码
         error && error(xhr.status)
       }
     }
   }
 }

/**
 * 保存 或 读取 cookie
 * @param key cookie 名
 * @param value cookie 值，可选，未传递，则会根据第一个参数 key 查询 cookie 值
 * @param options 可选，保存 cookie 时的选项 {expires, path, domain, secure}
 */
 function cookie(key, value, options = {}) {
  /* 读取 cookie */
  if (typeof value === 'undefined') {
    // 获取所有 cookie
    const allCookies = document.cookie.split('; ')
    // 循环，迭代每一条 cookie 信息
    for(let i = 0, len = allCookies.length; i < len; i++) {
      // 使用 = 号将 key=value 字符串分割
      const parts = allCookies[i].split('=')
      // 数组中第一个元素是 cookie 名，第二个及之后的元素以 = 连接的结果为 cookie 值
      const name = decodeURIComponent(parts.shift())
      const value = decodeURIComponent(parts.join('='))
      // 判断当前 cookie 名是否与待查询的 key 一致
      if (key === name) {
        return value
      }
    }
    return null
  }

  /* 保存 cookie */
  // 语法：document.cookie = 'key=value; expires=时间; path=路径; domain=域; secure'
  // 定义数组，用于保存 cookie 字符串中各项
  // 使用 encodeURIComponent 对 key 与 value 进行编码处理
  const cookies = [encodeURIComponent(key), '=', encodeURIComponent(value)]
  // 解构 options 中选项
  const {expires, path, domain, secure} = options
  if (expires) { // 有过期时间的设置
    if (typeof expires === 'number') { // 如果是数字的失效时间，则表示的是天数
      const datetime = new Date()
      datetime.setDate(datetime.getDate() + expires)
      cookies.push('; expires=' + datetime.toUTCString())
    }
  }
  // 判断 path、domain、secure
  cookies.push(
    path ? '; path=' + path : '; path=/',
    domain ? '; domain=' + domain : '',
    secure ? '; secure' : ''
  )
  // 保存 cookie
  document.cookie = cookies.join('')
}

/**
 * 删除 cookie
 */
function removeCookie(key, options = {}) {
  options.expires = -1
  cookie(key, '', options)
}

// 保存 cookie
function setCookie(key, value, options = {}) {
  // 使用 encodeURIComponent 对 key 与 value 进行编码处理
  const cookies = [encodeURIComponent(key), '=', encodeURIComponent(value)]
  // 解构 options 中选项
  const {expires, path, domain, secure} = options
  if (expires) { // 有过期时间的设置
    if (typeof expires === 'number') { // 如果是数字的失效时间，则表示的是天数
      const datetime = new Date()
      datetime.setDate(datetime.getDate() + expires)
      cookies.push('; expires=' + datetime.toUTCString())
    }
  }
  // 判断 path、domain、secure
  cookies.push(
    path ? '; path=' + path : '; path=/',
    domain ? '; domain=' + domain : '',
    secure ? '; secure' : ''
  )
  // 保存 cookie
  document.cookie = cookies.join('')
}

// 获取 cookie
function getCookie(key) {
  // 获取所有 cookie
  const allCookies = document.cookie.split('; ')
  // 循环，迭代每一条 cookie 信息
  for(let i = 0, len = allCookies.length; i < len; i++) {
    // 使用 = 号将 key=value 字符串分割
    const parts = allCookies[i].split('=')
    // 数组中第一个元素是 cookie 名，第二个及之后的元素以 = 连接的结果为 cookie 值
    const name = decodeURIComponent(parts.shift())
    const value = decodeURIComponent(parts.join('='))
    // 判断当前 cookie 名是否与待查询的 key 一致
    if (key === name) {
      return value
    }
  }
  return null
}

export function parseTime(time, pattern) {
	if (arguments.length === 0 || !time) {
		return null
	}
	const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
	let date
	if (typeof time === 'object') {
		date = time
	} else {
		if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
			time = parseInt(time)
		} else if (typeof time === 'string') {
			time = time.replace(new RegExp(/-/gm), '/');
		}
		if ((typeof time === 'number') && (time.toString().length === 10)) {
			time = time * 1000
		}
		date = new Date(time)
	}
	const formatObj = {
		y: date.getFullYear(),
		m: date.getMonth() + 1,
		d: date.getDate(),
		h: date.getHours(),
		i: date.getMinutes(),
		s: date.getSeconds(),
		a: date.getDay()
	}
	const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
		let value = formatObj[key]
		// Note: getDay() returns 0 on Sunday
		if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
		if (result.length > 0 && value < 10) {
			value = '0' + value
		}
		return value || 0
	})
	return time_str
}

export function formatWeek(cellValue) {
  if (cellValue == null || cellValue == "") return "";
  var date = new Date(cellValue)
  var year = date.getFullYear()
  var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  var week;
  if(date.getDay() == 0) week = "星期日"
  if(date.getDay() == 1) week = "星期一"
  if(date.getDay() == 2) week = "星期二"
  if(date.getDay() == 3) week = "星期三"
  if(date.getDay() == 4) week = "星期四"
  if(date.getDay() == 5) week = "星期五"
  if(date.getDay() == 6) week = "星期六"
  return year + '-' + month + '-' + day + ' ' + '('+ week + ')';
}